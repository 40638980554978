exports.install = function (Vue) {
  // Vue.prototype.$target = "http://35.158.1.252:8888/"; // 线上后端地址
  Vue.prototype.$target = "http://172.20.20.78:8888/"; //本地后端地址
  // 封装提示成功的弹出框
  Vue.prototype.notifySucceed = function (msg) {
    this.$notify({
      title: this.$t('prompt.success'),
      message: msg,
      type: "success",
      offset: 100
    });
  };
  // 封装提示失败的弹出框
  Vue.prototype.notifyError = function (msg) {
    this.$notify.error({
      title: this.$t('prompt.error'),
      message: msg,
      offset: 100
    });
  };
}