/*
 * @Description: 路由配置
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:58:48
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../components/Error.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/Forget.vue'),
    meta: {
      // requireAuth: true // 需要验证登录状态
    }
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('../views/Goods.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/brands',
    name: 'Brands',
    component: () => import('../views/Brands.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/eventscenter',
    name: 'Eventscenter',
    component: () => import('../views/Eventscenter.vue'),
    beforeEnter: (to, from, next) => {
      if (!to.query.id) {
        // 如果没有 id 参数，重定向到首页
        next({ name: 'Events' });
      } else {
        // 如果有 id 参数，继续导航
        next();
      }
    }
  },
  {
    path: '/goods/details',
    name: 'Details',
    component: () => import('../views/Details.vue'),
  },
  {
    path: '/quick/details',
    name: 'Details',
    component: () => import('../views/Details.vue'),
  },
  {
    path: '/details',
    name: 'Details',
    component: () => import('../views/Details.vue'),
    beforeEnter: (to, from, next) => {
      if (!to.query.spuId) {
        next({ name: 'Goods' });
      } else {
        next();
      }
    }
  },
  {
    path: '/personalcenter/Cart/details',
    name: 'Details',
    component: () => import('../views/Details.vue'),
  },
  {
    path: '/quick',
    name: 'Quick',
    component: () => import('../views/Quick.vue'),
    meta: {
      requireAuth: true // 需要验证登录状态
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/exploregigwi',
    name: 'ExploreGigwi',
    component: () => import('../views/ExploreGigwi.vue'),
  },
  {
    path: '/exploreskipdawg',
    name: 'ExploreSkipdawg',
    component: () => import('../views/ExploreSkipdawg.vue'),
  },
  {
    path: '/explorepetgeek',
    name: 'ExplorePetgeek',
    component: () => import('../views/ExplorePetgeek.vue'),
  },
  {
    path: '/exploregp',
    name: 'ExploreGp',
    component: () => import('../views/ExploreGp.vue'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue'),
  },
  {
    path: '/howplace',
    name: 'HowPlace',
    component: () => import('../views/HowPlace.vue'),
  },
  {
    path: '/policy',
    name: 'Policy',
    component: () => import('../views/Policy.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
  },
  {
    path: '/brandassets',
    name: 'Brandassets',
    component: () => import('../views/Brandassets.vue'),
  },
  {
    path: '/text',
    name: 'text',
    component: () => import('../views/text.vue'),
  },
  {
    path: '/placeOrder',
    name: 'PlaceOrder',
    component: () => import('../views/PlaceOrder.vue'),
    meta: {
      requireAuth: true // 需要验证登录状态
    }
  },
  {
    path: '/finishedorder',
    name: 'FinishedOrder',
    component: () => import('../views/FinishedOrder.vue'),
    meta: {
      requireAuth: true // 需要验证登录状态
    }
  },
  {
    path: '/finishedregister',
    name: 'FinishedRegister',
    component: () => import('../views/FinishedRegister.vue'),
  },
  {
    path: '/personalcenter',
    name: 'Personalcenter',
    component: () => import('../views/Personalcenter/Personalcenter.vue'),
    meta: {
      requireAuth: true // 需要验证登录状态
    },
    children: [
      {
        path: 'Orderlist',
        name: 'Orderlist',
        component: () => import('../views/Personalcenter/Orderlist.vue'),
      },
      {
        path: 'Ordersub',
        name: 'Ordersub',
        component: () => import('../views/Personalcenter/Ordersub.vue'),
      },
      {
        path: 'Cart',
        name: 'Cart',
        component: () => import('../views/Personalcenter/Cart.vue'),
      },
      {
        path: 'Profile',
        name: 'Profile',
        component: () => import('../views/Personalcenter/Profile.vue'),
      },
      {
        path: 'Insight',
        name: 'Insight',
        component: () => import('../views/Personalcenter/Insight.vue'),
      },
    ]
  },
  {
    path: '/mgoods',
    name: 'Mgoods',
    component: () => import('../views/mviews/mgoods.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: () => import('../views/NotFound.vue')

  },
]

const router = new Router({
  // base: '/dist',
  mode: 'history',
  routes
})
/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
