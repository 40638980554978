// 1、创建中文语言包对象
export default {
  menus: {
    home: '首页',
    quick: '订单',
    products: '产品目录',
    about: '关于贵为',
    contactUs: '联系我们',
    cooperate: '招商 合作',
    problem: '常见问题',
    partner: '成为合作伙伴',
    download: '下载',
    brands: '品牌'
  },
  prompt: {
    success: '成功',
    error: '错误',
    hint: '账号未激活',
    hint1: '账号或密码不正确',
    hint2: '登录成功',
    hint3: '退出登录成功',
    empty: '抱歉没有找到相关的商品，请看看其他的商品',
  },
  login: {
    title: '登录',
    hint: '字母开头,长度6-18之间,允许字母数字下划线',
    username: '请输入用户名',
    placeholder: '请输入账号',
    placeholder1: '请输入密码',
    placeholder2: '请输入您的验证码',
    retrieve: '找回密码',
  },
  mine: {
    personal: '个人信息',
    nickname: '昵称',
    nickname1: '请输入昵称',
    account: '账号',
    changepassword: '修改密码',
    newpassword: '新密码',
    confirmpassword: '确认密码',
    confirmpassword1: '请再次输入密码',
    confirmpassword2: '两次输入密码不一致!',
    save: '保存',
    reset: '重置',
    confirm: '确认修改',
    renew: '个人资料已更新',
    renew1: '密码已更新',
  },
  list: {
    ordertype: '下单类型',
    instock: '有库存',
    production: '生产',
    ordertogether: '拼单',
    brands: '品牌',
    category: '类别',
    cat: '猫',
    dog: '狗',
  },
  table: {
    productname: '产品名称',
    stockquantity: '库存数量',
    price: '单价',
    packingquantity: '装箱只数',
    consolidate: '是否拼箱',
    numberorders: '下单只数',
    total: '金额',
    yes: '是',
    no: '否',
    subtotal: '总计',
    pieces: '件',
    buy: '下单',
  },
  contactus: {
    content: '请输入内容',
    verification: '请输入验证码',
    verify: '请输入正确的验证码',
  },
  cart: {
    cart: '我的购物车',
    selectall: '全选',
    operate: '操作',
    continue: '继续购物',
    chosen: '已选择',
    total: '共',
    order: '提交订单',
    empty: '您的购物车还是空的！',
    go: '快去购物吧！',
    del: '是否确认删除此商品?',
    tip: '提示',
    confirm: '确定',
    think: '我再想想',
    deleted: '删除成功',
    ordered: '下单成功',
  },
  order: {
    orders: '我的订单',
    status: '订单状态',
    ordernum: '订单号',
    time: '下单时间',
    amount: '总数量',
    sum: '总金额',
    details: '明细',
    view: '查看明细',
    empty: '您的订单还是空的！',
    quantity: '数量',
    subtotal: '小计',
  },
  details: {
    style: '款式',
    move: '鼠标移至图上可放大图片'
  }
}