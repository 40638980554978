<template>
  <div id="myLogin">
    <el-dialog width="608px" center :visible.sync="isLogin">
      <el-form :model="LoginUser" :rules="rules" status-icon ref="ruleForm" class="demo-ruleForm">
        <el-form-item style="margin-bottom: 50px;">
          <p style="font-size: 28px;font-weight: bold;">Login</p>
          <p>Hello, login to see more products</p>
        </el-form-item>
        <el-form-item prop="name">
          <div style="border-bottom: 1px solid #000;">
            <img src="../assets/imgs/uploads/ic_outline-email.png" alt="" style="width: 20px;height: 20px;">
            <input type="text" style="height: 32px;width: 300px;margin-left: 20px;" placeholder="Email"
              v-model="LoginUser.name">
          </div>
        </el-form-item>
        <el-form-item prop="pass">
          <div style="border-bottom: 1px solid #000;">
            <img src="../assets/imgs/uploads/ph_lock-bold.png" alt="" style="width: 20px;height: 20px;">
            <input type="password" style="height: 32px;width: 300px;margin-left: 20px;" placeholder="Password"
              v-model="LoginUser.pass">
          </div>
        </el-form-item>
        <div style="margin: 45px 0 24px;display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex; align-items: center;">
            <input type="checkbox"><span style="font-size: 16px;margin-left: 5px;">Remember me</span>
          </div>
          <div style="text-decoration: underline;font-size: 16px;cursor: pointer;" @click="forgetPass">Forgot password?
          </div>
        </div>
        <el-form-item>
          <el-button size="medium" :disabled="isClick" type="primary" @click="Login" style="width:100%;">{{
            $t('login.title') }}</el-button>
        </el-form-item>
        <div style="margin: 10px 0 80px;">
          New to GiGwi？
          <router-link to="/register" style="font-weight: bold;color: #CA141D;">Sign up</router-link>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "MyLogin",
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('login.placeholder')));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const userNameRule = /^(?:[a-zA-Z0-9_]{6,18}|[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/;
      if (userNameRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error(this.$t('login.hint')));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error(this.$t('login.placeholder1')));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(
          new Error(this.$t('login.hint'))
        );
      }
    };
    return {
      isClick: false,
      LoginUser: {
        name: "",
        pass: ""
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }]
      }
    };
  },
  computed: {
    // 获取vuex中的showLogin，控制登录组件是否显示
    isLogin: {
      get() {
        return this.$store.getters.getShowLogin;
      },
      set(val) {
        this.$refs["ruleForm"].resetFields();
        this.setShowLogin(val);
      }
    }
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin"]),
    forgetPass() {
      this.isLogin = false
      this.$router.push("/forget")
    },
    Login() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate(valid => {
        //如果通过校验开始登录
        if (valid) {
          this.isClick = true;
          setTimeout(() => {
            this.isClick = false;
          }, 3000);
          this.$axios
            .post("/api/mall/member/login", {
              loginName: this.LoginUser.name,
              password: this.LoginUser.pass
            })
            .then(res => {

              if (res.data.code === 200) {
                // 隐藏登录组件
                this.isLogin = false;
                // 登录信息存到本地
                let user = JSON.stringify(res.data.data);
                // console.log(user)
                localStorage.setItem("user", user);
                // 登录信息存到vuex
                this.setUser(res.data.data);
                // 弹出通知框提示登录成功信息
                this.notifySucceed(this.$t('prompt.hint2'));
              } else if (res.data.code === 224) {
                // 清空输入框的校验状态
                this.$refs["ruleForm"].resetFields();
                // 弹出通知框提示登录失败信息
                this.notifyError(this.$t('prompt.hint'));
              } else {
                // 清空输入框的校验状态
                this.$refs["ruleForm"].resetFields();
                // 弹出通知框提示登录失败信息
                this.notifyError(this.$t('prompt.hint1'));
              }
            })
            .catch(err => {
              return Promise.reject(err);
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style>
.el-dialog {
  border-radius: 20px;
  padding: 30px 129px 30px;
}

.el-dialog__body {
  color: #000;
}

.el-dialog--center .el-dialog__body {
  padding: 0;
}

.el-dialog__headerbtn .el-dialog__close {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.el-dialog__headerbtn .el-dialog__close:hover {
  color: #000;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: #000;
  border-color: #000;
  color: #FFF;
}

.el-button--primary {
  color: #FFF;
  font-size: 16px;
  background-color: #000;
  border-color: #000;
}
</style>