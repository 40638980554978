<template>
  <div id="app" name="app">
    <el-container>
      <div class="topbar1">
        <div class="nav">
          <ul style="display:flex; justify-content: space-between; align-items: center;">
            <li @click="toTop" style="margin-right: 200px;">
              <div><img style="width: 129px; height: 36px;" src="./assets/imgs/uploads/GP logo.png">
              </div>
            </li>
            <li>
              <router-link to="/" exact><span>{{
                $t('menus.home') }}</span></router-link>
            </li>
            <!-- <li>
              <router-link to="/quick"><span>{{ $t('menus.quick') }}</span></router-link>
            </li> -->
            <li class="clickdiv1">
              <router-link to="/goods"><span>{{ $t('menus.products') }}</span></router-link>
              <div class="contarea1">
                <div style="margin-bottom: 15px;">
                  <router-link to="/goods">Products</router-link>
                </div>
                <div style="margin-bottom: 15px;"><router-link to="/quick" style="color: #000;">Place an
                    order</router-link></div>
                <div><router-link to="/howplace" style="color: #000;">How to place</router-link>
                </div>
              </div>
            </li>
            <li class="clickdiv">
              <router-link to="/brands"><span>{{ $t('menus.brands') }} <i class="el-icon-arrow-down"
                    style="font-weight: bold;"></i></span></router-link>
              <div class="contarea">
                <div style="margin-bottom: 15px;"><router-link to="/exploregigwi" style="color: #000;">GiGwi</router-link>
                </div>
                <div style="margin-bottom: 15px;">
                  <router-link to="/exploregp">Gifts for paws</router-link>
                </div>
                <div style="margin-bottom: 15px;">
                  <router-link to="/explorepetgeek">Petgeek</router-link>
                </div>
                <div><router-link to="/exploreskipdawg">Skipdawg</router-link></div>
              </div>
            </li>

            <li class="clickable-div">
              <router-link to="/about"><span>{{ $t('menus.about') }} <i class="el-icon-arrow-down"
                    style="font-weight: bold;"></i></span></router-link>
              <div class="content-area">
                <div style="margin-bottom: 15px;">
                  <router-link to="/about" style="color: #000;">About Us</router-link>
                </div>
                <div style="margin-bottom: 15px;"><router-link to="/contactUs" style="color: #000;">Contact
                    Us</router-link>
                </div>
                <div style="margin-bottom: 15px;"><router-link to="/faq">FAQs</router-link></div>
                <div><router-link to="/events">News and Events</router-link></div>
              </div>
            </li>
            <li>
              <div style="margin-top: -5px; width: 85px;">
                <el-dropdown placement="bottom" @command="handleCommand">
                  <span class="el-dropdown-link">
                    <div v-if="menuName">
                      <span><img style="width: 15px;height: 15px;" :src="menuName.img" alt=""></span>
                      <span style="margin-left: 6px;font-size: 12px;font-weight: bold;color: #333;">{{
                        menuName.value }}</span>
                    </div>
                    <div v-else>
                      <span class="el-dropdown-link">
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                    </div>
                  </span>
                  <el-dropdown-menu slot="dropdown" style="width: 150px;">
                    <el-dropdown-item v-for="item in languages" :key="item.value" :value="item.value" :command="item">
                      <span><img style="width: 15px;height: 15px;" :src="item.img" alt=""></span>
                      <span style="margin-left: 6px;">{{ item.value }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </li>
            <li style="margin-top: -6px; cursor: pointer;display: flex;align-items: center;"
              v-if="!this.$store.getters.getUser">
              <div @click="login">
                <span><img src="./assets/imgs/uploads/Ellipse 5.png" alt="" style="width: 10px;height: 12px;"></span>
                <span style="font-size: 12px; font-weight: bold;margin-left: 5px;">Login</span>
              </div>
              <router-link to="/register" style="margin-left: 15px;">
                <div class="sign">Sign up</div>
              </router-link>
            </li>
            <!-- <li v-if="!this.$store.getters.getUser" style="margin-top: -6px; cursor: pointer;">
              
            </li> -->
            <li v-if="this.$store.getters.getUser">
              <div @click="openShoppingCart" style="cursor: pointer;margin-top: -6px;display: flex;align-items: center;">
                <img src="./assets/imgs/uploads/fluent_cart-24-regular.png" alt="" style="height: 16px;width: 16px;">
                <div class="outnum">{{ getNum }}</div>
              </div>
            </li>
            <li v-if="this.$store.getters.getUser">
              <!-- <div @click="logout" style="margin-top: -6px; cursor: pointer;"> -->
              <div style="margin-top: -6px;">
                <router-link to="/personalcenter/Orderlist" active-class="">
                  <span><img src="./assets/imgs/uploads/user1.png" alt="" style="width: 16px;height: 16px;"></span>
                  <span style="font-size: 14px;margin-left: 6px;">Hi,{{ this.$store.getters.getUser.nickName }}</span>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 顶部导航栏END -->
      <!-- 登录模块 -->
      <MyLogin></MyLogin>
      <!-- 主要区域容器 -->
      <el-main class="main">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
      <!-- 主要区域容器END -->
      <!-- 底栏容器 -->
      <el-footer style="height: auto;">
        <div>
          <div class="footer">
            <div class="wrapper">
              <div>
                <div style="margin: 15px 0; font-size: 24px;font-weight: bold;display: flex;align-items: center;">
                  <div><img src="./assets/imgs/uploads/logo3.png" alt="" style="width: 61px;height: 61px;"></div>
                  <div style="width: 250px;margin-left: 10px;">Best Choice for Your Pet Business</div>
                </div>
                <div style="margin: 30px 0; font-size: 13px;display: flex;align-items: center;">
                  <el-input type="text" v-model="stemail" class="stemail" placeholder="Email address..."></el-input>
                  <el-button @click="subscription" :disabled="isClick1"
                    style="width: 105px;height: 39px;background-color: #000;color: #FFF;">Subscribe</el-button>
                  <div style="width: 110px;font-size: 15px;margin-left: 10px;font-weight: 550;">Receive Latest Offers From
                    Us</div>
                </div>
                <p style="margin: 10px 0; font-size: 20px;font-weight: bold;">SOCIAL MEDIA</p>
                <div style="width: 276px;display: flex;justify-content: space-between;margin-top: 20px;">
                  <a target="_blank" href="https://www.facebook.com/gigwipettoy"><img
                      src="./assets/imgs/uploads/ic_baseline-facebook.png" alt="" style="height: 55px;width: 55px;"></a>
                  <a target="_blank" href="https://twitter.com/PGigwi20003">
                    <img src="./assets/imgs/uploads/mdi_twitter.png" alt="" style="height: 55px;width: 55px;">
                  </a>
                  <a target="_blank" href="https://www.instagram.com/gigwi_us/">
                    <img src="./assets/imgs/uploads/mdi_instagram.png" alt="" style="height: 55px;width: 55px;">
                  </a>
                  <a target="_blank" href="https://www.youtube.com/channel/UCTuomUE3UbKiXv9bjTAz9Sg">
                    <img src="./assets/imgs/uploads/mdi_youtube.png" alt="" style="height: 55px;width: 55px;">
                  </a>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;width: 550px;">
                <div>
                  <p style="font-size: 20px;font-weight: bold;">ABOUT US</p>
                  <p class="faq"><router-link to="/about">About Us</router-link></p>
                  <router-link to="/contactUs">
                    <p class="faq">Contact Us</p>
                  </router-link>
                  <router-link to="/faq">
                    <p class="faq">FAQs</p>
                  </router-link>
                </div>
                <div>
                  <p style="font-size: 20px;font-weight: bold;">PAGES</p>
                  <router-link to="/" exact>
                    <p class="faq">Home</p>
                  </router-link>
                  <router-link to="/quick">
                    <p class="faq">Order</p>
                  </router-link>
                  <router-link to="/goods">
                    <p class="faq">Products</p>
                  </router-link>
                  <router-link to="/brands">
                    <p class="faq">Brands</p>
                  </router-link>
                </div>
                <div>
                  <p style="font-size: 20px;font-weight: bold;">COMMUNITY</p>
                  <a href="">
                    <p class="faq" style="width: 148px;color: #CA141D;font-weight: bold;">We'd love to hear your feedback
                      ！
                    </p>
                  </a>
                  <p class="faq" style="width: 133px;">Come & join our <a class="facebook">Facebook Group</a></p>
                </div>
              </div>
            </div>
          </div>
          <div style="background-color: #F3F3F3;">
            <div class="end">
              <div style="font-size: 14px;">Copyright © 2024, Gigwi(HK) Trading Co. Limited</div>
              <div style="display: flex;justify-content: space-between;width: 600px;align-items: center;">
                <router-link to="/terms" style="font-size: 14px;">Terms of Use</router-link>
                <router-link to="/policy" style="font-size: 14px;">Privacy Policy</router-link>
                <router-link to="/brandassets" style="font-size: 14px;">Brand Assets</router-link>
                <div @click="toTop"><img src="./assets/imgs/uploads/Back to top button.png" alt=""
                    style="width: 106px;height: 36px;cursor: pointer;"></div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
      <!-- 底栏容器END -->
      <el-drawer :modal="false" :visible.sync="drawer" direction="rtl" :size="drawerWidth">
        <div slot="title" style="display: flex;justify-content: space-between;">
          <div>
            <el-checkbox v-model="isAllCheck" style="margin-left: 0.35vw;"></el-checkbox>
            <span style="color: black; font-weight: bold;margin-left: 15px;">Shopping Cart</span>
          </div>
          <span style="margin-right: 15px;">({{ getCheckGoods.length }})Products</span>
        </div>
        <hr style="margin: 0 10px 10px 10px; color: #E4E4E4">
        <div class="smcart" style="overflow: hidden;">
          <div style="overflow-y: scroll;height: 30vw;" :class="minicart ? 'minicart' : ''">
            <div v-for="(item, index) in getShoppingCart" :key="item.id" class="cartItem"
              style="margin: 0 10px 10px 10px; border-bottom: 1px solid #E4E4E4; padding-bottom: 10px">
              <el-row>
                <el-col :span="2" style="text-align: center">
                  <el-checkbox :value="item.check" @change="checkChange($event, index)"></el-checkbox>
                </el-col>
                <el-col :span="4">
                  <img style=" height: 66px; width: 66px; border-radius: 5px; border: 1px solid #E4E4E4;"
                    v-lazy="item.imageUrl" />
                </el-col>
                <el-col :span="18">
                  <div
                    style="color: black;font-weight: 500;display:flex;justify-content: space-between;align-items: center;margin-bottom: 15px;">
                    {{ item.skuName }}
                    <div style="margin-right: 15px;">
                      <el-tag type="info" effect="plain" size="small"
                        style="border-radius: 25px; color: black;font-weight: 500">
                        {{ getLabelForStatus(item.proType) }}</el-tag><i class="el-icon-delete"
                        @click="deleteData(item.id)" style="margin-left: 15px;"></i>
                    </div>
                  </div>
                  <div>
                    <div style="display:inline-block;width:30%"><span style="color: #959595; font-size: 14px">${{
                      item.price
                    }}/Piece</span></div>
                    <div style="display:inline-block;width:35%">
                      <el-input-number v-if="item.isLcl === 0" style="width:120px;height:16px" :step="item.amount"
                        size="mini" :value="item.num" @change="handleChange($event, index, item.id)" :min="1"
                        step-strictly></el-input-number>
                      <el-input-number v-else style="width:120px;height:16px" size="mini" :value="item.num"
                        @change="handleChange($event, index, item.id)" :min="1"></el-input-number>
                    </div>
                    <div style="display:inline-block;width:35%;text-align: right">
                      <span>${{ formatF((item.price * 1000) * item.num / 1000) }}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div>
            </div>
          </div>
        </div>
        <div style="padding:10px; font-size: 12px;width: 100%;background-color: #FFF;margin-top: 13px;">
          <div style="color: #959595;font-size: 16px;">
            <p style="color: black; font-weight: bold; font-size: 18px">Price Details</p>
            <div>({{ getShoppingCart.length }} Products,{{ getNum }} Pieces)</div>
            <div style="display: flex;justify-content: space-between;margin-top: 5px;">
              <p>Sub-Total</p>
              <p>USD$ {{ getTotalPrice }}</p>
            </div>
            <p style="margin-top: 20px;"><el-button class="hovsend" :disabled="isClick" type="primary" @click="send"
                style="width: 100%">PLACE ORDER</el-button></p>
            <p>
              <span @click="tocart"
                style="display: flex;justify-content: center;color: #333;font-size: 18px;cursor: pointer;margin-top: 19px;">VIEW
                CART</span>
            </p>
            <p style="text-align: center;">Note: This is the EXW price ,Not include logistics, customs declaration etc.
            </p>
          </div>
        </div>
      </el-drawer>
    </el-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  metaInfo: {
    title: 'GiGwi - official website | Dog toys ,Cat toys, supplies',
    meta: [
      {
        name: 'description',
        content: 'Welcome to our cat and dog pet toy store! Discover a variety of adorable toys to bring endless fun to your pets. Explore our website to find a wide range of cat and dog toys, including plush toys, teaser wands, and more, catering to different pet preferences.Curated selection of high-quality cat and dog toys, safe and reliable, promoting the healthy and happy growth of your pets.Browse our website and choose the most suitable toys for your beloved pets, allowing them to enjoy more joyful moments.Offering diverse options of cat and dog toys to meet the needs of pets of different ages and breeds, bringing joy and vitality to them.',
      },
      {
        name: 'keywords',
        content: 'Gigwi,Gifts For Paws,PETGEEK,SHIPDAWG,Cat and dog toys, Plush toys,Teaser wands,Pet toy store,Cat and dog supplies,Adorable toys,Pet supplies website,Fun moments,Happy pets,Safe toys',
      },
    ],
  },
  beforeUpdate() {
    this.activeIndex = this.$route.path;
  },
  data() {
    return {
      menuName: {
        value: 'English',
        img: require('./assets/imgs/uploads/English.jpg'),

      },
      isPc: true,
      languages: [
        {
          name: 'en',
          value: 'English',
          img: require('./assets/imgs/uploads/English.jpg'),
        },
        {
          name: 'zh',
          value: '中文(简体)',
          img: require('./assets/imgs/uploads/Chinese.png'),

        }
      ],
      gotop: false,
      value: '',
      isClick: false,
      isClick1: false,
      activeIndex: "", // 头部导航栏选中的标签
      search: "", // 搜索条件
      register: false, // 是否显示注册组件
      visible: false,// 是否退出登录
      highlightedIndex: null, // 选中的文字索引
      drawer: false,
      drawerWidth: '35%', // Drawer宽度
      stemail: '',
      minicart: false,
    };
  },
  created() {
    // 获取浏览器localStorage，判断用户是否已经登录
    if (localStorage.getItem("user")) {
      // 如果已经登录，设置vuex登录状态
      this.setUser(JSON.parse(localStorage.getItem("user")));
    }
    // 页面加载时初始化
    // this.updateNavbarVisibility();
  },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll, true);
  //   // 绑定滚动事件
  //   this.$el.addEventListener('scroll', this.handleScroll);
  // },
  computed: {
    ...mapGetters([
      "getUser",
      "getNum",
      "getShoppingCart",
      "getCheckGoods",
      "getCheckNum",
      "getTotalPrice",
      "getNum",
    ]),
    userLoggedIn() {
      // 通过 Vuex 的 getter 获取用户登录状态
      return this.$store.getters.getUser !== "";
    },
    isAllCheck: {
      get() {
        return this.$store.getters.getIsAllCheck;
      },
      set(val) {
        this.$store.commit('checkAll', val);
      }
    }

  },
  watch: {
    // 获取vuex的登录状态
    getUser: function (val) {
      if (val === "") {
        // 用户没有登录
        this.setShoppingCart([]);
        this.$router.push({ path: '/' })
      } else {
        // 用户已经登录,获取该用户的购物车信息
        this.$axios
          .post("/api/mall/cart/" + val.id)
          .then(res => {
            if (res.data.code === 200) {
              // 001 为成功, 更新vuex购物车状态
              this.setShoppingCart(res.data.data);
            } else {
              // 提示失败信息
              this.notifyError(res.data.message);
            }
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }
    },
  },
  mounted() {
    this.$router.afterEach((to) => {
      if (to.path !== '/quick') {
        window.scrollTo(0, 0);
      }
    })
    this.updatecartHeight();
    window.addEventListener('resize', this.updatecartHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updatecartHeight);
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin", "setShoppingCart", "updateShoppingCart", "deleteShoppingCart", "checkAll"]),
    login() {
      // 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
      this.setShowLogin(true);
      this.showContent1 = false;
    },
    // 退出登录
    logout() {
      this.visible = false;
      // 清空本地登录信息
      localStorage.setItem("user", "");
      // 清空vuex登录信息
      this.setUser("");
      this.notifySucceed(this.$t('prompt.hint3'));
    },
    // 接收注册子组件传过来的数据
    isRegister(val) {
      this.register = val;
    },
    handleCommand(command) {
      this.translate(command.name)
      this.menuName = command
    },
    // 点击搜索按钮
    searchClick() {
      if (this.search != "") {
        // 跳转到全部商品页面,并传递搜索条件
        this.$router.push({ path: "/goods", query: { search: this.search } });
        this.search = "";
      }
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    translate(lang) {
      this.$i18n.locale = lang
    },
    openShoppingCart() {
      this.drawer = true
      // this.$axios.post('', {})
    },
    checkChange(val, key) {
      // 更新vuex中购物车商品是否勾选的状态
      this.updateShoppingCart({ key: key, prop: "check", val: val });
    },
    handleChange(currentValue, key, id) {
      // 当修改数量时，默认勾选
      this.updateShoppingCart({ key: key, prop: "check", val: true });
      // 向后端发起更新购物车的数据库信息请求
      this.$axios
        .post("/api/mall/cart/updateCart", {
          id: id,
          num: currentValue
        })
        .then(res => {
          switch (res.data.code) {
            case 200:
              // 更新vuex状态
              this.updateShoppingCart({
                key: key,
                prop: "num",
                val: currentValue
              });
              break;
            default:
              this.notifyError(res.data.message);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    deleteData(id) {
      var that = this
      this.$confirm(this.$t('cart.del'), this.$t('cart.tip'), {
        confirmButtonText: this.$t('cart.confirm'),
        cancelButtonText: this.$t('cart.think'),
        type: 'warning',
        center: true
      }).then(() => {
        that.deleteItem(id)
      }).catch(() => {
      });
    },
    deleteItem(id) {
      this.$axios
        .post("/api/mall/cart/deleteCart", {
          memberId: this.$store.getters.getUser.id,
          id: id
        })
        .then(res => {
          switch (res.data.code) {
            case 200:
              // “001” 删除成功
              // 更新vuex状态
              this.deleteShoppingCart(id);
              // 提示删除成功信息
              this.notifySucceed(this.$t('cart.deleted'));
              break;
            default:
              // 提示删除失败信息
              this.notifyError(res.data.message);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    send() {
      if (this.getCheckNum > 0) {
        this.$router.push({
          path: '/placeorder',
        }),
          this.drawer = false
      } else {
        this.isClick = true;
        setTimeout(() => {
          this.isClick = false;
        }, 2000);
        this.$notify({
          message: 'Please select the items.',
          type: 'warning',
          offset: 400
        });
      }
    },
    getLabelForStatus(status) {
      var a = status + ''
      switch (a) {
        case '0':
          return this.$t('list.instock');
        case '1':
          return this.$t('list.production');
        case '2':
          return this.$t('list.ordertogether');
        default:
          return 'unknown';
      }
    },
    formatF(value) {
      let value1 = Math.round(parseFloat(value) * 100) / 100;
      let s = value.toString().split(".");
      if (s.length == 1) {
        value1 = value.toString() + ".00";
        return value1;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value1 = value.toString() + "0";
        }
        return value1;
      }
    },
    tocart() {
      this.$router.push({
        path: '/personalcenter/Cart',
      }),
        this.drawer = false
    },
    updatecartHeight() {
      if (window.innerWidth < 1745) {
        this.minicart = true
      } else {
        this.minicart = false
      }
    },
    subscription() {
      this.isClick1 = true;
      setTimeout(() => {
        this.isClick1 = false;
      }, 3000);
      if (this.stemail) {
        this.$axios
          .post("/api/mall/subscription/subscription",
            {
              userId: this.$store.getters.getUser ? this.$store.getters.getUser.id : '',
              userName: this.$store.getters.getUser ? this.$store.getters.getUser.nickName : '',
              email: this.stemail
            })
          .then(res => {
            if (res.data.code === 200) {
              this.$message({
                showClose: true,
                message: 'You will receive a subscription message if you enter the correct email',
                type: 'success',
                center: true
              });
              this.stemail = ''
            } else {
              this.notifyError(res.data.message);
            }
          })
          .catch(err => {
            return Promise.reject(err);
          });
      } else {
        this.$message.error('Please enter email');
      }
    }
  }
};
</script>

<style>
.router-link-active,
.active123 {
  color: #CA141D !important;
}

.el-drawer__body {
  overflow: auto;
}

.topbar1 {
  width: 100%;
  align-items: center;
  position: fixed;
  z-index: 9;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 5px 10px -5px #D9D9D9;
  background-color: #FFF;
}

.topbar1 .nav {
  width: 1280px;
  margin: 0 auto;
}

.topbar1 .nav li {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}


.lnout {
  float: right;
  width: 130px;
  padding: 10px 5px !important;
  background-color: #FFA9A4 !important;
}

.lnout:hover {
  background-color: #efc7a8 !important;
}

.lnout span {
  font-size: 24px;
  color: #fff;
}


.topbar1 .nav li a {
  color: #333;
}


.language .el-input__inner {
  border: none;
}

.clickable-div {
  cursor: pointer;
  position: relative;
  margin-right: 50px;
}

.clickdiv {
  cursor: pointer;
  position: relative;
}

.clickdiv1 {
  cursor: pointer;
  position: relative;
}

.clickable-div:hover .content-area,
.content-area:hover {
  display: block;
}

.clickdiv:hover .contarea,
.contarea:hover {
  display: block;
}

.clickdiv1:hover .contarea1,
.contarea:hover {
  display: block;
}

.outnum {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 17px;
  margin-left: 6px;
  border-radius: 19px;
  font-size: 12px;
  color: #fff;
  background-color: #333;
}

.sign {
  width: 86px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  font-size: 12px;
  color: #FFF;
  background-color: #CA141D;
  border-radius: 20px;
}

.content-area {
  display: none;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: -60px;
  width: 204px;
  height: 195px;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  padding: 42px 0 0 25px;
  border-radius: 0 0 10px 10px;
  background-image: url('./assets/imgs/uploads/bg1.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.contarea {
  display: none;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: -60px;
  width: 204px;
  height: 195px;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  padding: 42px 0 0 25px;
  border-radius: 0 0 10px 10px;
  background-image: url('./assets/imgs/uploads/bg1.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.contarea1 {
  display: none;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: -60px;
  width: 204px;
  height: 170px;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  padding: 42px 0 0 25px;
  border-radius: 0 0 10px 10px;
  background-image: url('./assets/imgs/uploads/bg11.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-primary-disabled {
  pointer-events: none;
}

.hovsend:hover {
  background-color: #5A5A5A !important;
}

/* 顶部导航栏CSS END */

/* 底栏容器CSS */
.el-footer {
  padding: 0 !important;
}

.footer {
  height: 450px;
  padding-top: 100px;
  background-color: #F3F3F3;
  /* margin-top: 64px; */
}

.footer .wrapper {
  width: 1280px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.faq {
  font-size: 16px;
  margin-top: 20px;
}

.end {
  width: 1280px;
  height: 83px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  background-color: #F3F3F3;
}

.facebook {
  text-decoration: underline;
  cursor: pointer;
}

.main {
  margin-top: 76px;
  padding: 0px !important;
}


.mouse-cover-canvas {
  left: 50% !important;
  top: 10% !important;
}

.stemail {
  width: 234px !important;
  height: 39px !important;
}

.el-input__inner:focus {
  border-color: #000 !important;
}

/* 底栏容器CSS END */
</style>