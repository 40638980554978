<template>
  <div id="myList" class="myList">
    <ul>
      <li v-for="item in list" :key="item.id">
        <div @click="todetails(item.id)">
          <img v-lazy="item.imgUrl" alt />
          <div style="font-size: 12px;font-weight: bold;">{{ item.brand }}</div>
          <div style="font-size: 12px;font-weight: bold;">{{ item.name }}</div>
          <button class="btn">More Info</button>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MyList",
  // list为父组件传过来的商品列表
  // isMore为是否显示“浏览更多”
  props: ["list", "isMore", "isDelete"],
  data() {
    return {};
  },
  computed: {
    // 通过list获取当前显示的商品的分类ID，用于“浏览更多”链接的参数   
    categoryID: function () {
      let categoryID = [];
      if (this.list != "") {
        for (let i = 0; i < this.list.length; i++) {
          const id = this.list[i].category_id;
          if (!categoryID.includes(id)) {
            categoryID.push(id);
          }
        }
      }
      return categoryID;
    }
  },
  methods: {
    todetails(id){
      this.$router.push({
                path: '/goods/details',
                query: {
                  spuId: id 
                }
            })
    }
  }
};
</script>
<style scoped>
.myList ul li {
  z-index: 1;
  float: left;
  width: 235px;
  height: 315px;
  padding: 10px 0;
  margin: 0 0 14.5px 10px;
  background-color: white;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
  cursor: pointer;
}

.myList ul li:hover {
  z-index: 2;
  border-color: #333;
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}

.myList ul li img {
  display: block;
  width: 220px;
  height: 220px;
  margin: 0 auto;
}
.myList ul li h2 {
  margin-top: 25px;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 400;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.myList ul li h3 {
  margin: 5px 10px;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #b0b0b0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.myList ul li p {
  margin: 10px 20px 10px;
  color: #ff6700;
}

.myList ul li p .del {
  margin-left: 0.5em;
  color: #b0b0b0;
  text-decoration: line-through;
}

.myList #more {
  text-align: center;
  line-height: 280px;
}

.myList #more a {
  font-size: 18px;
  color: #333;
}

.myList #more a:hover {
  color: #ff6700;
}
.btn{
  margin-top: 6px;
  width: 189px;
  height: 24px;
  font-weight: bold;
  border-radius: 5px;
  background-color: #333;
  color: #FFF;
  cursor: pointer;
}</style>