// 2、创建英文语言包对象
export default {
    menus: {
        home: 'Home',
        quick: 'Order',
        products: 'Products',
        about: 'About Us',
        contactUs: 'ContactUs',
        cooperate: 'InvestmentPromotion Cooperate',
        problem: 'Common problem',
        partner: 'Become a partner',
        download: 'DownLoad',
        brands: 'Our Brands'
    },
    prompt: {
        success: 'success',
        error: 'error',
        hint: 'Account is not activated',
        hint1: 'The account or password is incorrect',
        hint2: 'Login successfully',
        hint3: 'Log out successfully',
        empty: 'Sorry, no relevant products were found, please look at other products',
    },
    login: {
        title: 'LOGIN',
        hint: 'Starting with a letter, length between 6-18, alphanumeric and underline allowed',
        placeholder: 'Please input Username',
        placeholder1: 'Please enter password',
        placeholder2: 'enter verification code',
        retrieve: 'Retrieve password',
    },
    mine: {
        personal: 'Personal information',
        nickname: 'Nick name',
        nickname1: 'Please enter a nickname',
        account: 'Account',
        changepassword: 'Change password',
        newpassword: 'New password',
        confirmpassword: 'Confirm password',
        confirmpassword1: 'Please enter password again',
        confirmpassword2: 'The password entered twice is inconsistent!',
        save: 'Save',
        reset: 'Reset',
        confirm: 'Confirm the changes',
        renew: 'Profile has been updated',
        renew1: 'Password has been updated',
    },
    list: {
        ordertype: 'Order type',
        instock: 'In Stock',
        production: 'Pre-Order',
        ordertogether: 'GroupBuy',
        brands: 'Brands',
        category: 'CATEGORY',
        cat: 'Cat',
        dog: 'Dog',
    },
    table: {
        productname: 'Product',
        stockquantity: 'Qty in stock',
        price: 'Unit Price',
        packingquantity: 'Packingquantity',
        consolidate: 'Whether to consolidate',
        numberorders: 'Qty',
        total: 'Amount',
        yes: 'Yes',
        no: 'No',
        subtotal: 'Subtotal',
        pieces: 'Pieces',
        buy: 'Buy Now',
    },
    contactus: {
        content: 'Please enter content',
        verification: 'please enter verification code',
        verify: 'Please enter correct verify code',
    },
    cart: {
        cart: 'Shopping cart',
        selectall: 'Select all',
        operate: 'Operate',
        continue: 'Continue shopping',
        chosen: 'Chosen',
        total: 'Total',
        order: 'Submit Order',
        empty: 'Your shopping cart is still empty !',
        go: 'Go shopping!',
        del: 'Are you sure to delete this product?',
        tip: 'Tip',
        confirm: 'Confirm',
        think: 'Think again',
        deleted: 'successfully deleted',
        ordered: 'successfully ordered',
    },
    order:{
        orders: 'Orders',
        status: 'Status',
        ordernum: 'Order No.',
        time: 'Date',
        amount: 'Qty',
        sum: 'Amount',
        details: 'Details',
        view: 'View details',
        empty: 'Your order is empty!',
        quantity: 'Quantity',
        subtotal: 'Subtotal',
    },
    details: {
        style: 'Style',
        move: 'Move your mouse over the image to enlarge it'
      }
}